@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP&display=swap');

*, *::before, *::after {
  box-sizing: border-box;
}

* {
  margin: 0;
}

html, body, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, code,
del, dfn, em, img, q, dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td {
  margin: 0;
  padding: 0;
  border: 0;
  font-weight: inherit;
  font-style: inherit;
  font-size: 100%;
  font-family: inherit;
}

input, button, textarea, select {
  font: inherit;
}

img, picture, video, canvas, svg {
  display: block;
  max-width: 100%;
}

p, h1, h2, h3, h4, h5, h6 {
  overflow-wrap: break-word;
}

em {
	font-style: italic;
}

strong {
	font-weight: bold;
}

html,
body {
	margin:0;
	padding:0;
	height:100%;
  scroll-behavior: smooth;
}

body{
  font-family: 'Noto Sans JP', sans-serif;
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
}

#root{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  isolation: isolate;
}

a{
  text-decoration: none;
  color: inherit;
}

li{
  list-style: none;
}

::-webkit-scrollbar {
  width: 7px;
}

::-webkit-scrollbar-track {
  background: #141A26;
}

::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background: #2d4c5f;
}

::-webkit-scrollbar-corner{
  border-radius: 5px;
}